



















































































































































import Vue from 'vue'
import cloneDeep from 'lodash.clonedeep'

// components
import CommissionQuotaAttainmentGroups from '@/components/dashboard/CommissionQuotaAttainmentGroups.vue'
import PivotBarChart from '@/components/charts/PivotBarChart.vue'

// utils
import {
  AmountField,
  amountFields,
  Query,
} from '@/components/charts/chart-settings'

type LocationType = {
  label: string
  field: string
  finder: ((input: string) => string) | ((input: number) => string)
}
type LocationTypes = {
  county: LocationType
  state: LocationType
  city: LocationType
  zip: LocationType
}
const locationTypes: LocationTypes = {
  county: {
    label: 'County',
    field: 'countyStateCountry',
    finder: (input: string) => input,
  },
  state: { label: 'State', field: 'state', finder: (input: string) => input },
  city: {
    label: 'City',
    field: 'cityStateCountry',
    finder: (input: string) => input,
  },
  zip: {
    label: 'Zip',
    field: 'zip',
    finder: (input: string) => input,
  },
}

type ColumnFields = {
  productCategoryId: LocationType
  productId: LocationType
  customerId: LocationType
}

export default Vue.extend({
  metaInfo: {
    title: 'Locations Charts - Dashboard',
  },
  components: {
    CommissionQuotaAttainmentGroups,
    PivotBarChart,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data: () => ({
    amountFieldId: 'postedAmount',
    amountFields: amountFields,

    columnFieldId: null as keyof ColumnFields | null,

    locationTypeValue: 'county' as keyof LocationTypes,

    dialogData: undefined as
      | undefined
      | {
          amountFieldId: string
          columnFieldId: keyof ColumnFields | null
        },
  }),
  computed: {
    locationTypes(): LocationTypes {
      return locationTypes
    },
    locationType(): LocationType {
      return locationTypes[this.locationTypeValue]
    },
    columnField(): LocationType | { field: null; finder: null } {
      if (!this.columnFieldId) return { field: null, finder: null }
      return this.columns[this.columnFieldId]
    },
    columns(): ColumnFields {
      return {
        productCategoryId: {
          label: 'Product Category',
          field: 'productCategoryId',
          finder: (input: number) =>
            (
              this.$store.getters.getProductCategoryById(input) || {
                abbr: input.toString(),
              }
            ).abbr,
        },
        productId: {
          label: 'Product',
          field: 'productId',
          finder: (input: number) =>
            (
              this.$store.getters.getProductById(input) || {
                abbr: input.toString(),
              }
            ).abbr,
        },
        customerId: {
          label: 'Customer',
          field: 'customerId',
          finder: (input: number) =>
            (
              this.$store.getters.getCustomerById(input) || {
                abbr: input.toString(),
              }
            ).abbr,
        },
      }
    },
    amountField(): AmountField {
      return (
        this.amountFields.find(z => z.id === this.amountFieldId) ||
        amountFields[0]
      )
    },
    where(): Query {
      const query: Query = {}
      return query
    },
  },
  methods: {
    openDialog(): void {
      this.dialogData = cloneDeep({
        amountFieldId: this.amountFieldId,
        columnFieldId: this.columnFieldId,
      })
    },
    closeDialog(): void {
      this.dialogData = undefined
    },
    applyDialog() {
      if (!this.dialogData) throw new Error('no dialog data')

      this.amountFieldId = this.dialogData.amountFieldId
      this.columnFieldId = this.dialogData.columnFieldId

      this.closeDialog()
    },
  },
})
