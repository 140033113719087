var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('commission-quota-attainment-groups',{staticClass:"pt-16 pt-sm-0",attrs:{"period":_vm.period,"cc-sm":"12","cc-md":"6","cc-lg":"6"},scopedSlots:_vm._u([{key:"top-right",fn:function(){return [_c('v-select',{staticClass:"mr-2",staticStyle:{"width":"128px"},attrs:{"solo":"","hide-details":"","label":"Type","items":Object.entries(_vm.locationTypes).map(function (ref) {
        var key = ref[0];
        var value = ref[1];

        return (Object.assign({}, value,
          {value: key}));
}),"item-text":"label","item-value":"value"},model:{value:(_vm.locationTypeValue),callback:function ($$v) {_vm.locationTypeValue=$$v},expression:"locationTypeValue"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',[_vm._v("$settings")])],1),_c('v-dialog',{attrs:{"value":!!_vm.dialogData,"persistent":"","width":"500"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Chart Settings ")]),(_vm.dialogData)?_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-select',{attrs:{"label":"Amount","items":_vm.amountFields,"item-value":"id","item-text":"label","hide-details":""},model:{value:(_vm.dialogData.amountFieldId),callback:function ($$v) {_vm.$set(_vm.dialogData, "amountFieldId", $$v)},expression:"dialogData.amountFieldId"}})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-select',{attrs:{"label":"Column","items":Object.entries(_vm.columns).map(function (ref) {
                var key = ref[0];
                var value = ref[1];

                return (Object.assign({}, value,
                    {value: key}));
}),"item-value":"value","item-text":"label","clearable":"","hide-details":""},model:{value:(_vm.dialogData.columnFieldId),callback:function ($$v) {_vm.$set(_vm.dialogData, "columnFieldId", $$v)},expression:"dialogData.columnFieldId"}})],1)],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.applyDialog()}}},[_vm._v(" Apply ")])],1)],1)],1)]},proxy:true},{key:"commission-component-group",fn:function(ref){
                  var group = ref.group;
                  var item = ref.item;
return [_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('pivot-bar-chart',{attrs:{"where":Object.assign({}, {companyId: group.company.id,
          salesAreaId: group.salesArea.id,
          salespersonId: group.salesperson.id,
          commissionQuotaPeriodId: group.commissionQuotaPeriod.id,
          currency: group.currency,
          commissionComponentGroupId: item.commissionComponentGroup.id},
          _vm.where),"title":(item.commissionComponentGroup.abbr + ' Total').toUpperCase(),"amount-field":_vm.amountField.id,"amount-label":((_vm.amountField.label) + " (" + (group.currency) + ")"),"amount-formatter":_vm.amountField.formatter(group),"data-label-amount-formatter":_vm.amountField.dataLabelFormatter(group),"axis-amount-formatter":_vm.amountField.axisFormatter(group),"row-field":_vm.locationType.field,"row-label":_vm.locationType.label,"row-finder":_vm.locationType.finder,"column-field":_vm.columnField.field,"column-finder":_vm.columnField.finder,"sort-desc":_vm.amountField.sortDesc,"horizontal":true,"stacked":true}})],1)]}},{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('pivot-bar-chart',{attrs:{"where":Object.assign({}, {companyId: item.company.id,
          salesAreaId: item.salesArea.id,
          salespersonId: item.salesperson.id,
          commissionQuotaPeriodId: item.commissionQuotaPeriod.id,
          currency: item.currency,
          commissionComponentId: item.commissionComponent.id},
          _vm.where),"title":(
            item.commissionComponent.abbr +
            ' / ' +
            item.commissionComponent.group.abbr
          ).toUpperCase(),"amount-field":_vm.amountField.id,"amount-label":((_vm.amountField.label) + " (" + (item.currency) + ")"),"amount-formatter":_vm.amountField.formatter(item),"data-label-amount-formatter":_vm.amountField.dataLabelFormatter(item),"axis-amount-formatter":_vm.amountField.axisFormatter(item),"row-field":_vm.locationType.field,"row-label":_vm.locationType.label,"row-finder":_vm.locationType.finder,"column-field":_vm.columnField.field,"column-finder":_vm.columnField.finder,"sort-desc":_vm.amountField.sortDesc,"horizontal":true,"stacked":true}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }